@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* LeafLoader styles */
.leaf-loader {
  width: 80px;  /* Increased from 60px to 80px */
  height: 80px; /* Increased from 60px to 80px */
  margin: 0 auto;
}

.leaf-loader svg {
  width: 100%;
  height: 100%;
}

.leaf-loader .leaf-background {
  fill: rgba(0, 0, 0, 0.1);
}

.leaf-loader .quadrant {
  fill: #d0d0d0;  /* Lightened the inactive color */
  stroke: #b0b0b0;
  stroke-width: 2;  /* Increased from 1 to 2 */
  transition: fill 0.3s ease, stroke 0.3s ease, stroke-width 0.3s ease;
}

.leaf-loader .quadrant.active {
  fill: #fdc400;  /* Changed to #fdc400 */
  stroke: #e0b000;  /* Darker stroke for contrast */
  stroke-width: 3;  /* Increased from 2 to 3 */
}

/* Date input styles */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::-webkit-datetime-edit-fields-wrapper,
input[type="date"]::-webkit-datetime-edit-text {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit-fields-wrapper,
input[type="date"]:focus::-webkit-datetime-edit-text,
input[type="date"]:valid::-webkit-datetime-edit-fields-wrapper,
input[type="date"]:valid::-webkit-datetime-edit-text {
  color: black;
}


/* Custom scrollbar for WebKit browsers (e.g., Chrome, Safari) */
.privacy-popup ::-webkit-scrollbar {
  width: 8px;
}

.privacy-popup ::-webkit-scrollbar-track {
  background: #2c2c2c;
}

.privacy-popup ::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 10px;
  border: 2px solid #2c2c2c; /* Optional: adds padding around the thumb */
}

/* Scrollbar styles for Firefox */
.privacy-popup {
  scrollbar-width: thin;
  scrollbar-color: #555 #2c2c2c;
}
