.react-calendar {
  background-color: white;
  color: black;
  border-radius: 10px;
  padding: 10px; /* Added padding around the calendar */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.react-calendar__tile {
  transition: background-color 0.3s ease;
}

.react-calendar__tile:hover {
  background-color: #e0e0e0;
}

.react-calendar__tile--active {
  background-color: black !important; /* Current date background */
  color: white !important; /* Current date text color */
}


/* customCalendar.css */
.select-right {
  text-align: right;
  /* For Firefox */
  -moz-text-align-last: right;
  /* For other browsers */
  text-align-last: right;
}
